/// <reference types="vite/client" />

/**
 * This file is used to capture the code version at Vite build time. This is to
 * get around the fact we only build assets when there are asset changes yet,
 * deploys can continue advancing in code versions.
 */
window.__codeVersion = import.meta.env.VITE_CODE_VERSION || "unknown"

declare global {
  interface Window {
    __codeVersion?: string
  }
}
